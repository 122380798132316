import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Social Media App Template | Social Media App Design | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/social-media-mobile-app/"
    metaDescription="Create the perfect mobile app design for your social media app today using our social media mobile app template."
    description="
    h2:Connected: our intuitive social media app design template
    <br/>
    Our social media app design template, Connected, will help you bring your social media idea to life in a functioning prototype. Boasting the most commonly recognizable features from your favorite apps, Connected is easy to set up, and even easier to customize, helping you turn your app idea into <a:https://uizard.io/prototyping/>UX/UI prototype</a> reality.
    <br/>
    h3:Realize your social media app design prototype on screen in minutes
    <br/>
    What are the most important features of any social media app? The clue is in the name. If you want to successfully bring your social media app design prototype to life, you need to think about your user and how your app will empower them socially. For this reason, Connected comes with all the must-have social media app features built-in.
    <br/>
    h3:Connected is easy to use, and boasts all the features your app design could possibly need
    <br/>
    Get connected now and bring your design prototype to life. Simply open the template in the Uizard app and get creative. Our <a:https://uizard.io/templates/mobile-app-templates/>mobile app template</a> comes fully kitted out with what your app design will need; however, changes are easy and intuitive with Uizard, meaning you can really put your own spin on things, allowing you to bring your idea to life in seconds.
    "
    pages={[
      "A clear and clean log-in page mirroring the core UX principles of mainstream social media apps",
      "A sign-up page demonstrating how new users interact with your social media mobile app",
      "On-page features of the app interface include a notification bell to demonstrate how your users will interact with your app on a minute-to-minute basis",
      "The interface also boasts a 'stories' carousel, similar to the most popular image-sharing platforms out there. From the perspective of a user, you can then navigate to your own 'stories', or explore the stories of your connections",
      "Native screens also demonstrate how your potential users will post an image, communicate with other users, search your app's network, and navigate to their own profile",
    ]}
    projectCode="XXJO55KQQAt67GmPKj6Z"
    img1={data.image1.childImageSharp}
    img1alt="social media app design template overview"
    img2={data.image2.childImageSharp}
    img2alt="social media app design template feed screen"
    img3={data.image3.childImageSharp}
    img3alt="social media app design template photo screen"
    img4={data.image4.childImageSharp}
    img4alt="social media app design template inbox screen"
    img5={data.image5.childImageSharp}
    img5alt="social media app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/social-media-mobile-app/social-media-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/social-media-mobile-app/social-media-app-feed.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/social-media-mobile-app/social-media-app-photo.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/social-media-mobile-app/social-media-app-inbox.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/social-media-mobile-app/social-media-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
